function isValidFileName(fileName) {
  if (
    fileName === null
    || fileName === undefined
    || fileName.length === 0
    || fileName === '.'
    || fileName === 'cmd'
    || fileName === 'cpanfile'
    || fileName === 'cpanfile.snapshot'
    || fileName.startsWith('./')
    || fileName.startsWith('/')
  ) {
    return false;
  }

  if (!/^[a-zA-Z0-9.\/_-]+$/.test(fileName)) {
    return false;
  }

  if (/\.\./.test(fileName)) {
    return false;
  }

  var elements = fileName.split('/');

  if (elements.length > 4) {
    return false;
  }

  var isOk = true;

  elements.forEach(function(el) {
    if (el.length === 0 || el.length > 50) {
      isOk = false;
    }
  });

  return isOk;
}

// If we're running under Node,
if(typeof exports !== 'undefined') {
    exports.isValidFileName = isValidFileName;
}
