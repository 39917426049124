import React, { Component } from 'react';

class Footer extends Component {

  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
<section className="section" style={{backgroundColor: '#f5f5f5'}}>
<nav className="level">
  <div className="level-left">
  </div>

  <div className="level-right">
    <p className="level-item"><a href="/terms-of-service">Terms of Service</a></p>
    <p className="level-item"><a href="/cookie-policy">Cookie Policy</a></p>
    <p className="level-item"><a href="/documentation" className="footer-fa-icon-link"><img src="/fa/book-solid.svg" alt="book-solid icon" className="footer-fa-icon"/>&nbsp;Documentation</a></p>
    <p className="level-item"><a href="https://x.com/PerlBanjo" className="footer-fa-icon-link"><img src="/fa/x-twitter-brands-solid.svg" alt="x-twitter-brands-solid icon" className="footer-fa-icon"/>&nbsp;PerlBanjo</a></p>
    <p className="level-item"><a href="mailto:info@perlbanjo.com" className="footer-fa-icon-link"><img src="/fa/envelope-solid.svg" alt="envelope-solid icon" className="footer-fa-icon"/>&nbsp;info@perlbanjo.com</a></p>
    <p className="level-item">2019..2025</p>
  </div>
</nav>
</section>
    );
  }

}

export default Footer;
